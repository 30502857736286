const intersectionObservers: Array<IntersectionObserver> = [];

function floatArrayEquals(a: readonly number[], b: readonly number[] | number) {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => Math.abs(val - b[index]) < 0.00001)
    );
}

function getIntersectionObserver(options: IntersectionObserverInit) {
    let intersectionObserver = intersectionObservers.find((observer) => {
        return (
            observer.root == options.root &&
            observer.rootMargin == options.rootMargin &&
            floatArrayEquals(observer.thresholds, options.threshold)
        );
    });
    if (intersectionObserver) return intersectionObserver;
    intersectionObserver = new IntersectionObserver((entries) => {
        // entries.sort((a, b) => a.intersectionRatio - b.intersectionRatio);
        // entries[0].target.dispatchEvent(new CustomEvent("mainView"));
        entries.forEach((entry) => {
            const eventName = entry.isIntersecting ? 'enterViewport' : 'exitViewport';
            entry.target.dispatchEvent(new CustomEvent(eventName, { detail: entry }));
        });
    }, options);
    intersectionObservers.push(intersectionObserver);
    return intersectionObserver;
}

export default function viewport(element: HTMLElement, options: IntersectionObserverInit = {}) {
    const intersectionObserver = getIntersectionObserver(options);
    intersectionObserver.observe(element);

    return {
        destroy() {
            intersectionObserver.unobserve(element);
        },
    };
}
