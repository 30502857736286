import viewportAction from './useViewportAction';

function isElementInViewport(el) {
    let top = el.offsetTop;
    let left = el.offsetLeft;
    const width = el.offsetWidth;
    const height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    return (
        top < window.pageYOffset + window.innerHeight &&
        left < window.pageXOffset + window.innerWidth &&
        top + height > window.pageYOffset &&
        left + width > window.pageXOffset
    );
}

export default function lazyimage(
    element: HTMLImageElement,
    { useLazyLoading, size } = { useLazyLoading: true, size: 1 }
) {
    if (useLazyLoading === false) {
        return;
    }

    if (isElementInViewport(element)) {
        applyImage(size);
        return;
    }

    const root = element.closest('.embla__viewport');
    const rootMargin = `600px 200px 600px 200px`;
    const options: IntersectionObserverInit = { root, rootMargin, threshold: [0, 0.5, 1] };
    const viewportActionMethods = viewportAction(element, options);
    element.addEventListener('enterViewport', enterViewport);

    function enterViewport() {
        applyImage(size);
        element.removeEventListener('enterViewport', enterViewport);
    }

    function applyImage(displaySizeFraction = 0.5) {
        const breakPoints = [2000, 1600, 1400, 1000];
        let sizesString = breakPoints.reduce((acc, point) => {
            return (acc += `(min-width: ${point}px) ${point * displaySizeFraction}px,`);
        }, '');
        sizesString += '80vw';
        element.sizes = sizesString;
        element.srcset = element.dataset.srcset;
        element.src = element.dataset.src;
        delete element.dataset.srcset;
        delete element.dataset.src;
    }

    return {
        destroy() {
            viewportActionMethods.destroy();
            element.removeEventListener('enterViewport', enterViewport);
        }
    };
}
